<template>
    <div class="page-layout page-container">
        <SidePanel id="side-panel" />
        <div class="content">
            <Breadcrumbs />
            <router-view v-bind="$attrs" keep-alive :offset="140"/>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "@common/components/base/Breadcrumbs";
import SidePanel from "@nidl/components/layout/SidePanel";

export default {
    name: "PageLayout",
    components: {
        Breadcrumbs,
        SidePanel
    },
}
</script>

<style scoped>
.page-layout{
    display: flex;
    align-items: flex-start;
}
.page-layout .content{
    flex-grow: 1;
}
::v-deep(.text-cell>ol>li::marker){
    font-size: 1.4rem;
}
::v-deep(.text-cell ol){
    padding: 1rem;
}
::v-deep(.text-cell ol>li){
    background-color: #E2E8D4;
    background-color: var(--light-color);
    padding: 0.5rem;
    margin: 0.5rem 0 0.5rem 3rem;
    color: #344b1b;
    color: var(--dark-color);
    text-shadow: none;
}
.content{
    display: flex;
    flex-direction: column;
}
.page-view{
    flex-grow: 1;
    margin: 0.5rem;
    
    min-height: 60vh;
}
::v-deep(.breadcrumbs a){
    color: #000;
    color: var(--dark-color);
    text-decoration: underline;
}
::v-deep(.breadcrumb){
    color: #E2E8D4;
    color: var(--dark-color);
    text-align: end;
}
::v-deep(.base-form),::v-deep(.form-title){
    max-width: 30rem;
    margin: 1rem auto;
}
::v-deep(.base-form .row){
    padding: 0.4rem;
}
::v-deep(.base-form .row:hover),::v-deep(.base-form .row:focus-within){
    background: transparent;
    font-weight: 700;
}
::v-deep(.base-form .col-label){
    text-align: right;
}
::v-deep(.base-form .btn-success){
    padding: 0.6rem 2rem;
    margin: 1rem;
    float: right;
}
::v-deep(.base-form .col-label){
    font-size: 1rem;
    padding-right: 1rem;
}
::v-deep(.base-form .cell){
    border: 1px solid #1d1b1a;
    background: transparent;
    border-radius: 0.5rem;
    min-height: 2rem;
    padding: 0.3rem 0.5rem;
}
::v-deep(.base-form .cell-textarea){
    height: 6rem;
}
::v-deep(.base-form .icon.orange_star){
    background-image: none;
    background: radial-gradient(#f98f5a,transparent 50%);
    width: 1rem;
}

::v-deep(.base-form .col-label){
    width: 25%;
}
::v-deep(.base-form .col-input){
    width: 75%;
}

@media screen and (max-width: 800px) {
    ::v-deep(.breadcrumbs){
        margin-left: 3rem;
    }
    
    .page-layout{
        flex-direction: column;
        align-items: stretch;
    }
}
@media screen and (max-width: 600px) {
    ::v-deep(.text-cell ol>li){
        padding: 0;
        margin: 0;
        display: block;
    }
    ::v-deep(.text-cell ol){
        padding: 0;
        margin: 0;
    }
    
    ::v-deep(.base-form .col-label),::v-deep(.base-form .col-input){
        width: 100%;
        text-align: left;
        margin-bottom: 0.2rem;
    }
    
    ::v-deep(.base-form){
        margin: 0;
    }
    
}
</style>