<template>
    <aside class="side-panel">
        <Menu id="side_menu" :menu_key="'side_menu'" :layer_hidden="2" :horizontal="false" :button_type="'arrow'"/>
    </aside>
</template>

<script>
import Menu from "@common/components/cms_app/menu/Menu"

export default {
    name: "SidePanel",
    components: {
        Menu,
    },
}
</script>

<style scoped>
.side-panel{
    /* position: sticky;
    top: 0;
    z-index: 1; */
    width: 20rem;
    min-width: 20rem;
    background: white;
    background: var(--white);
    margin: 0.5rem;
    border-radius: 0.5rem;
    border: 4px solid var(--border-color);
}
.side-panel ::v-deep(nav>ul){
    padding: 1rem
}
.side-panel ::v-deep(li){
    padding: 0.5rem;
}

.side-panel ::v-deep(li>ul){
    padding: 0rem
}
.side-panel ::v-deep(li.level1 a){
    display: block;
    padding: 0.5rem;
    color: #000024;
    color: var(--dark-color);
    background: #E5F1FB;
    background: var(--button-color);
}
::v-deep(.menu-list) {
    background: white;
    background: var(--white);
}
@media only screen and (max-width: 800px) {
    .side-panel{
        width: 2rem;
        height: 2rem;
        min-width: initial;
        border-radius: 50%;
        border: none;
    }
    .side-panel ::v-deep(.button){
        padding: 0;
        height: 2rem;
        width: 2rem;
        --dark-color: var(--primary-color)
    }
    
    .side-panel ::v-deep(.menu>.menu-list){
        border: 4px solid var(--border-color);
        margin: 0.5rem 1rem;
        width: calc(100% - 2rem);
        z-index: 10;
        border-radius: 1rem;
    }
}
</style>